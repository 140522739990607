document.addEventListener('DOMContentLoaded', function() {
    "use strict";
  
    // Hover functionality for desktop
    var menuItems = document.querySelectorAll('.menu__item');
    menuItems.forEach(function(menuItem) {
        menuItem.addEventListener('mouseenter', function(e) {
            if (window.innerWidth > 1070) {
                menuItem.classList.add('active'); // Adds 'active' class on hover
            }
        });
  
        menuItem.addEventListener('mouseleave', function(e) {
            if (window.innerWidth > 1070) {
                menuItem.classList.remove('active'); // Removes 'active' class when the mouse leaves
            }
        });
    });
  
    // Click functionality for mobile and touch devices
    var subMenuItems = document.querySelectorAll('.menu__item--has-sub > span');
    subMenuItems.forEach(function(span) {
        span.addEventListener('click', function(e) {
            if (window.innerWidth <= 1070) {
                var parent = span.parentElement;
                // Toggle submenu visibility and active class
                parent.classList.toggle('submenu__active', !parent.classList.contains('submenu__active'));
                // Remove active class from siblings
                var siblings = Array.prototype.filter.call(parent.parentElement.children, function(child) {
                    return child !== parent;
                });
                siblings.forEach(function(sibling) {
                    sibling.classList.remove('submenu__active');
                });
            }
        });
    });
  
    // Remove 'submenu__active' class when resizing browser from <= 1070px to larger
    window.addEventListener('resize', function() {
        if (window.innerWidth > 1070) {
            var activeSubMenus = document.querySelectorAll('.submenu__active');
            activeSubMenus.forEach(function(activeSubMenu) {
                activeSubMenu.classList.remove('submenu__active');
            });
        }
    });
  
    // Mobile menu toggle
    var mobileMenu = document.querySelector('.menu-mobile');
    if (mobileMenu) {
        mobileMenu.addEventListener('click', function(e) {
            document.body.classList.toggle('menu-opened');
            e.preventDefault();
        });
    }
});
